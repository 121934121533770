import Vue from "vue";

import "@/plugins/service/index.js";
import { ENDPOINTS } from "@/plugins/service/Endpoints.js";
import { otmizacao, regrasDoNegocio } from "../../package.json";

// CONFIGURAÇÃO DE COSTUMIZAÇÃO
Vue.prototype.$config = new Vue({
    data: () => ({
        // TODO: VARIAVEIS
        ENDPOINTS: ENDPOINTS,
        modalAlertInteresse: false,
        modalAlertSucesso: false,
        modalAlertSucessoInteresse: false,
        conf: {},
        recaptchaModal: false,
        contato: {
            email: "",
            nome: "",
            assunto: "",
            mensagem: "",
            _recaptcha: "",
        },
        regrasDoNegocio: regrasDoNegocio,
        dados: otmizacao, // package.json
        snackbar: {
            show: false,
            text: "",
            color: "",
            time: 3000,
        },
        width: 1024,
        conteudo: [],
        faq: [],
        paises: [],
        estados: [],
        cidades: [],
        cotacao: [],
        gateway: [],
        gatewaySelected: false,
        showModalMoeda: false,
        cotacaoM: false,
        moedaModel: false,
        debug: false,
    }),

    methods: {
        removeCache() {
            // TODO: removeCache
            if (
                this.$axios.getLocal("v") &&
                process.env.VUE_APP_VERSION &&
                process.env.VUE_APP_VERSION != this.$axios.getLocal("v").data
            ) {
                caches.keys().then((cacheNames) => {
                    cacheNames.forEach((cacheName) => {
                        caches.delete(cacheName);
                    });
                });
                console.warn("removendo o cache de arquivos!");
                return;
            }

            this.$axios.setLocal("v", process.env.VUE_APP_VERSION);
        },

        mobile() {
            // TODO: mobile
            const toMatch = [
                /Android/i,
                /webOS/i,
                /iPhone/i,
                /iPad/i,
                /iPod/i,
                /BlackBerry/i,
                /Windows Phone/i,
            ];

            return toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            });
        },

        responsive() {
            // TODO: responsive

            const self = this;
            window.addEventListener("resize", mydevice);

            function mydevice() {
                self.width = window.innerWidth;
            }

            return self.width;
        },

        alert(msn, color = this.dados.corprimaria, time = 3000) {
            // TODO: alert
            this.snackbar.text = msn;
            this.snackbar.show = true;
            this.snackbar.color = color;
            this.snackbar.time = time;
        },

        alertWarn(msn, color = "#c93720", time = 3000) {
            // TODO: alertWarn
            this.snackbar.text = msn;
            this.snackbar.show = true;
            this.snackbar.color = color;
            this.snackbar.time = time;
        },

        // redirect() {
        //     if(!this.$axios.getLocal('modelo') && document.location.pathname.length > 6) {
        //         this.$axios.setLocal('redirect', btoa(document.location.href));
        //         router.push({ name: 'Landpage', query: { r: btoa(document.location.href) }});
        //     }
        // },

        formatoPadrao(horas) {
            // TODO: formatoPadrao
            switch (horas) {
                case 13:
                    return 1;
                    break;
                case 14:
                    return 2;
                    break;
                case 15:
                    return 3;
                    break;
                case 16:
                    return 4;
                    break;
                case 17:
                    return 5;
                    break;
                case 18:
                    return 6;
                    break;
                case 19:
                    return 7;
                    break;
                case 20:
                    return 8;
                    break;
                case 21:
                    return 9;
                    break;
                case 22:
                    return 10;
                    break;
                case 23:
                    return 11;
                    break;
                case 24:
                    return 0;
                    break;
                default:
                    return horas;
            }
        },

        formatTime(hora) {
            // TODO: formatTime
            let lang = "es";

            if (this.$axios.getLocal("lang")) {
                lang = this.$axios.getLocal("lang").data.substring(0, 2);
            }

            const d = new Date(hora);
            const ano = new Intl.DateTimeFormat(lang, {
                year: "numeric",
                timeZone: "America/Sao_Paulo",
            }).format(d);
            const mes = new Intl.DateTimeFormat(lang, {
                month: "long",
                timeZone: "America/Sao_Paulo",
            }).format(d);
            const dia = new Intl.DateTimeFormat(lang, {
                day: "2-digit",
                timeZone: "America/Sao_Paulo",
            }).format(d);
            const h = d.getUTCHours();
            const hen = this.formatoPadrao(h); // (d.getUTCHours() % 12) + 3; // OS 3 É O FUSO HORÁRIO

            // let string =  ano + ', ' +  mes + ', ' +  dia + ', ' +  h

            if (lang != "en") {
                return dia + " de " + mes + " de " + ano + " - " + h + "h";
            } else {
                return (
                    mes +
                    " " +
                    dia +
                    ", " +
                    ano +
                    " - " +
                    hen +
                    (h >= 12 ? "PM" : "AM")
                );
            }
        },

        selectLayout(modelo) {
            // TODO: selectLayout

            this.$axios.setLocal("modelo", modelo);
            this.$axios.setLocal("layout", "pre-cadastro");

            // this.$axios.setLocal('modelo', modelo);
            // this.$axios.setLocal('layout', 'pre-cadastro');

            // if(this.$axios.getLocal('redirect')) {

            //     let params = atob(this.$axios.getLocal('redirect').data);
            //     this.$axios.destroy('redirect')

            //     if(params.indexOf('http') != -1) {
            //         location.href = params
            //         return
            //     }

            // }

            // this.$axios.rota('JogosVenda')
        },

        getConfig() {
            // TODO: getConfig
            this.$axios.get(this.ENDPOINTS.CONFIGS).then((response) => {
                this.conf = response;
            });
        },

        async getConteudo(param, retorno = false) {
            // TODO: getConteudo
            // privacidade
            // cookies
            // condicoes_site
            // condicoes_ingresso
            // popup
            // hospitalidade
            // patrocinadores

            let idconteudo = null;
            if (!retorno) {
                await this.$axios
                    .get(this.ENDPOINTS.GETCONTEUDOS + param)
                    .then((response) => {
                        if (response && !(typeof response[0] === "undefined")) {
                            if (response[0].subtitulo == "app") {
                                idconteudo = response[0].idconteudo;
                                this.$axios
                                    .get(
                                        this.ENDPOINTS.GETCONTEUDO + idconteudo
                                    )
                                    .then((res) => {
                                        this.conteudo = res;
                                    });
                            } else if (response && response[0].idconteudo) {
                                idconteudo = response[0].idconteudo;
                                this.$axios
                                    .get(
                                        this.ENDPOINTS.GETCONTEUDO + idconteudo
                                    )
                                    .then((res) => {
                                        this.conteudo = res;
                                    });
                            }
                        } else if (response && response[0].idconteudo) {
                            idconteudo = response[0].idconteudo;
                            this.$axios
                                .get(this.ENDPOINTS.GETCONTEUDO + idconteudo)
                                .then((res) => {
                                    this.conteudo = res;
                                });
                        }
                    });
            } else {
                return this.$axios.get(this.ENDPOINTS.GETCONTEUDOS + param);
            }
        },

        getSacPerguntas() {
            // TODO: getSacPerguntas
            this.$axios.get(this.ENDPOINTS.GETSACPERGUNTAS).then((response) => {
                this.faq = response;
            });
        },
    },
});

// Vue.prototype.$config.selectLayout('normal');
Vue.prototype.$config.getConfig();
